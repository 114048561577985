// import { Input } from 'antd'
import React from 'react'
import './App.css'
import 'antd/dist/antd.css'
import { Button, Input } from 'antd'

async function getImages(query) {
  const url = 'https://serverless-api.etalko.workers.dev'
  // const url = 'http://localhost:8787'

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ query }),
    headers: {
      'Content-type': 'application/json',
    },
  })

  return res.json()
}

function App() {
  const [query, setQuery] = React.useState('')
  const [images, setImages] = React.useState([])

  const updateQuery = (e) => {
    setQuery(e.target.value)
  }

  const search = async () => {
    if (!query) return
    const res = await getImages(query)
    setImages(res)
  }

  return (
    <div className="App">
      <Input
        value={query}
        onChange={updateQuery}
        placeholder="Type your search query"
      />
      <Button type="primary" onClick={search}>
        Search
      </Button>

      <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => {
          return (
            <li
              key={image.id}
              style={{
                flexBasis: '33%',
                listStyle: 'none',
                overflow: 'hidden',
              }}
            >
              <a href={image.link}>
                <img
                  src={image.imgSrc}
                  alt=""
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default App
